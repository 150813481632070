import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDeviceList() {
  // Use toast
  const toast = useToast()
  const refSpecListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'name', sortable: true },
    { key: 'nominal', sortable: true },
    { key: 'min', sortable: true },
    { key: 'max', sortable: true },
    { key: 'unit.name', label: "Unit", sortable: true },
    { key: 'tagID', sortable: true },
    { key: 'isActive' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalDevices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const row = ref([])
  const search = ref('')
  const workcenters = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refSpecListTable.value ? refSpecListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalDevices.value,
    }
  })

  const refetchData = () => {
    refSpecListTable.value.refresh()
    GetData()
  }

  watch([currentPage, perPage, search], () => {
    refetchData()
  })

  function GetWorkCenters() {
    return new Promise((resolve, reject) => {
      store.dispatch('pamis-parameter/fetchWorkCenters')
        .then(response => {
          var workCenterDeviceResult = [];

          if (response.data != null && response.data.length > 0) {
            var workCenterList = response.data.filter(x => x.isDelete == false && x.isActive == true)
            workCenterList = formatParentChildForTreeSelect(workCenterList);
            workCenterList.forEach(node => removeEmptyChildren(node));

            row.value = workCenterList;
            resolve(workCenterList);
            workcenters.value = workCenterList;
          } else {
            reject(workCenterList)
          }
        })
        .catch(() => {
          reject(workCenterDeviceResult)
        })
    })
  }

  function removeEmptyChildren(node) {
    if (node.children.length === 0) {
      delete node.children;
    } else {
      node.children.forEach(child => removeEmptyChildren(child));
    }
  }

  function formatParentChildForTreeSelect(data) {
    const idToData = {};
    const rootItems = [];

    data.forEach(item => {
      const { id, name, parentID } = item;
      const newItem = { id, label: name, children: [] };

      idToData[id] = newItem;

      if (parentID === 0) {
        rootItems.push(newItem);
      } else {
        const parentItem = idToData[parentID];
        if (parentItem) {
          if (!parentItem.children) {
            parentItem.children = [];
          }
          parentItem.children.push(newItem);
        }
      }
    });

    return rootItems;
  }

  async function GetData() {
    await GetWorkCenters();
    // GetDevices();
  }

  GetData()


  return {
    row,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalDevices,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refSpecListTable,
    refetchData
  }
}
