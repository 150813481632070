<template>
  <b-sidebar
    id="add-new-parameter-sidebar"
    :visible="isAddNewParameterSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-parameter-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{
            actionType == 0
              ? " Add New Parameter"
              : actionType == 1
              ? " Update Parameter"
              : "Delete Parameter"
          }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Parameter Name"
            rules="required"
          >
            <b-form-group label="Parameter Name" label-for="parameter-name">
              <b-form-input
                id="parameter-name"
                v-model="currentParameter.name"
                :disabled="actionType == 2"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Parameter Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group label="Tag">
            <v-select
              id="tagId"
              v-model="currentTag"
              label="name"
              :options="tagList"
              :disabled="actionType == 2"
              class="select-size-lg"
            >
              <template #option="{ id, name }">
                <span> {{ name }}</span
                ><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group label="Nominal" label-for="parameter-nominal">
            <b-form-input
              id="parameter-nominal"
              v-model="currentParameter.nominal"
              :disabled="actionType == 2"
              autofocus
              trim
              placeholder="Nominal"
            />
          </b-form-group>

          <b-row>
            <b-col md="6">
              <b-form-group label="Min" label-for="parameter-min">
                <b-form-input
                  id="parameter-min"
                  v-model="currentParameter.min"
                  :disabled="actionType == 2"
                  autofocus
                  trim
                  placeholder="Min"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Max" label-for="parameter-max">
                <b-form-input
                  id="parameter-max"
                  v-model="currentParameter.max"
                  :disabled="actionType == 2"
                  autofocus
                  trim
                  placeholder="Max"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="Unit">
            <v-select
              id="unitid"
              v-model="currentParameter.unit"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="Unit "
              :options="units"
              :filter="filter"
              :reduce="(option) => option.id"
              class="select-size-lg"
              :disabled="action == 2"
            >
              <template slot="selected-option" slot-scope="option">
                <span>{{ option.name }}</span>
              </template>
              <template #option="{ id, name }">
                <span> {{ name }}</span
                ><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>
          <!--
          <b-form-group label="Unit" label-for="parameter-unit">
            <b-form-input
              id="parameter-unit"
              v-model="currentParameter.unit"
              :disabled="actionType == 2"
              autofocus
              trim
              placeholder="Unit"
            />
          </b-form-group>
          -->

          <b-form-checkbox
            class="mt-2"
            :disabled="actionType == 2"
            id="input-isParameterActive"
            v-model="currentParamIsActive"
          >
            Is Active
          </b-form-checkbox>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-if="actionType != 2"
              class="mr-2"
              type="submit"
            >
              {{
                currentParameter == null || currentParameter.id == null
                  ? "Add"
                  : "Update"
              }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              v-if="
                currentParameter != null &&
                currentParameter.id != null &&
                actionType == 2
              "
              variant="outline-danger"
              @click="deleteParameter"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSelect,
  BFormCheckbox,
  BRow,
  BCol,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { inject, toRefs, ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import parameter from "@/router/parameter";

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    Treeselect,
  },
  created() {
    this.fetchWorkCenters();
  },
  computed: {
    ...mapGetters({ units: "pamis-parameter/GetUnits" }),
  },
  methods: {
    filter(options, search) {
      console.log(options, search);
      return options.filter((option) => {
        let label = option.name;
        if (typeof label === "number") {
          label = label.toString();
        }
        return label.includes(search);
      });
    },
    fetchWorkCenters() {
      this.$store
        .dispatch("pamis-parameter/fetchWorkCentersWithParameter")
        .then((result) => {
          if (result != null) {
            this.workCenters = result.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Workcenter By Client Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    fetchTags(currentWorkCenter, tagId) {
      this.$store
        .dispatch("pamis-parameter/fetchTagsByDeviceGuid", currentWorkCenter)
        .then((result) => {
          if (result != null) {
            this.tagList = result.data;

            if (tagId != null) {
              this.currentTag = this.tagList.find((x) => x.id == tagId);
            } else {
              this.currentTag = null;
            }
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Tags By Client Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    deleteParameter() {
      const parameterRequest = {};

      parameterRequest.id = this.currentParameter.id;
      parameterRequest.specId = this.currentSpec.id;

      this.$store
        .dispatch("pamis-parameter/deleteParameter", parameterRequest)
        .then((result) => {
          this.$emit("refetch-data");
          this.$emit("update:is-add-new-parameter-sidebar-active", false);
          this.notification();
        });
    },
    submitForm() {
      var process = "pamis-parameter/addParameter";

      if (this.currentParameter != null && this.currentParameter.id != null) {
        process = "pamis-parameter/updateParameter";
      }

      if (this.currentTag != null) {
        this.currentParameter.tagID = this.currentTag.id;
      } else {
        this.currentParameter.tagID = null;
      }

      this.currentParameter.isActive = this.currentParamIsActive;

      const parameterRequest = {};

      parameterRequest.parameter = this.currentParameter;
      parameterRequest.specId = this.currentSpec.id;
      parameterRequest.parameter.unitId = parseInt(
        this.currentParameter.unit,
        10
      );
      console.log(this.currentParameter);
      this.$store.dispatch(process, parameterRequest).then((result) => {
        this.$emit("refetch-data");
        this.$emit("update:is-add-new-parameter-sidebar-active", false);
        this.notification();
      });
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Process Successfully",
          icon: "BellIcon",
        },
      });
    },
  },

  watch: {
    parameterdata: {
      handler: function (n, o) {
        if (n == null || n == undefined || n.selectedParameter.id == null) {
          this.currentParameter = {
            id: null,
            name: null,
            nominal: null,
            min: null,
            max: null,
            unit: null,
          };
          this.currentTag = null;

          var workcenter = this.workCenters.find(
            (x) => x.id == n.selectedParameter.currentWorkCenter
          );
          this.fetchTags(workcenter, null);
        } else {
          this.currentParameter = n.selectedParameter;
          this.currentParamIsActive = n.selectedParameter.isActive;

          if (n.selectedParameter.isChanged) {
            this.currentTag = null;
            n.selectedParameter.isChanged = false;
          }

          if (this.currentTag == null) {
            var currentWorkCenter = n.selectedParameter.currentWorkCenter;
            var workcenter = this.workCenters.find(
              (x) => x.id == currentWorkCenter
            );
            this.fetchTags(workcenter, n.selectedParameter.tagID);
          }
        }
      },
      deep: true,
    },
    specdata: {
      handler: function (n, o) {
        this.currentSpec = n.selectedSpec;
      },
      deep: true,
    },
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewParameterSidebarActive",
    event: "update:is-add-new-parameter-sidebar-active",
  },
  props: {
    isAddNewParameterSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      required,
      alphaNum,
      email,
      workCenters: [],
      currentSpec: null,
      currentTag: null,
      currentParamIsActive: true,
      currentParameter: {
        id: null,
        name: null,
        nominal: null,
        min: null,
        max: null,
        unit: null,
      },
      tagList: null,
    };
  },

  inject: ["parameterdata", "specdata"],

  setup(props, { emit }) {
    const blankParameterData = {
      id: null,
      name: null,
      nominal: null,
      min: null,
      max: null,
      unit: null,
    };

    const parameterData = ref(JSON.parse(JSON.stringify(blankParameterData)));
    const resetParameterData = () => {
      parameterData.value = JSON.parse(JSON.stringify(blankParameterData));
      emit("refetch-data");
      emit("update:is-add-new-parameter-sidebar-active", false);
    };

    const onSubmit = () => {
      store
        .dispatch("pamis-parameter/addParameter", currentParameter)
        .then(() => {});
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetParameterData);

    return {
      parameterData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-parameter-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
