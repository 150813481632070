import axios from '@axios'

export default {
  namespaced: true,
  state: {
    units: [],
  },
  getters: {
    GetUnits(state) {
      return state.units
    },
  },
  mutations: {
    SetUnit(state, data) {
      state.units = data;
    },
  },
  actions: {
    GetUnits(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/parameter/Unit/Units')
          .then(response => {
            ctx.commit("SetUnit", response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchWorkCenters(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/WorkCenter')
          .then((response) => {

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchWorkCentersWithParameter(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/parameter/WorkCenter')
          .then((response) => {

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchSpecByWorkcenter(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/parameter/Specification/SpecsByWorkCenterId?WorkCenterId=' + data)
          .then((response) => {

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchParameterBySpecId(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/parameter/Parameter/ParametersBySpecId?SpecId=' + data.id)
          .then((response) => {

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchTagsByDeviceGuid(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/dc-wizard/Tag/TagsByDeviceGuid?DeviceGuid=' + data.deviceGUID)
          .then((response) => {

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchProducts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/parameter/Product/GetProducts')
          .then((response) => {

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addSpec(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/parameter/Specification/Create', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSpec(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/parameter/Specification/Update?id=' + data.specification.id, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cloneSpec(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/parameter/Specification/Clone', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSpec(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/parameter/Specification/Delete?id=' + id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addParameter(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/parameter/Parameter/Create', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateParameter(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/parameter/Parameter/Update?id=' + data.parameter.id, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteParameter(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/parameter/Parameter/Delete?id=' + data.id + '&specId=' + data.specId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
