var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-parameter-sidebar","visible":_vm.isAddNewParameterSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-parameter-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.actionType == 0 ? " Add New Parameter" : _vm.actionType == 1 ? " Update Parameter" : "Delete Parameter")+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Parameter Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Parameter Name","label-for":"parameter-name"}},[_c('b-form-input',{attrs:{"id":"parameter-name","disabled":_vm.actionType == 2,"autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"Parameter Name"},model:{value:(_vm.currentParameter.name),callback:function ($$v) {_vm.$set(_vm.currentParameter, "name", $$v)},expression:"currentParameter.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Tag"}},[_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"tagId","label":"name","options":_vm.tagList,"disabled":_vm.actionType == 2},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var id = ref.id;
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(id)+" ")])]}}],null,true),model:{value:(_vm.currentTag),callback:function ($$v) {_vm.currentTag=$$v},expression:"currentTag"}})],1),_c('b-form-group',{attrs:{"label":"Nominal","label-for":"parameter-nominal"}},[_c('b-form-input',{attrs:{"id":"parameter-nominal","disabled":_vm.actionType == 2,"autofocus":"","trim":"","placeholder":"Nominal"},model:{value:(_vm.currentParameter.nominal),callback:function ($$v) {_vm.$set(_vm.currentParameter, "nominal", $$v)},expression:"currentParameter.nominal"}})],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Min","label-for":"parameter-min"}},[_c('b-form-input',{attrs:{"id":"parameter-min","disabled":_vm.actionType == 2,"autofocus":"","trim":"","placeholder":"Min"},model:{value:(_vm.currentParameter.min),callback:function ($$v) {_vm.$set(_vm.currentParameter, "min", $$v)},expression:"currentParameter.min"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Max","label-for":"parameter-max"}},[_c('b-form-input',{attrs:{"id":"parameter-max","disabled":_vm.actionType == 2,"autofocus":"","trim":"","placeholder":"Max"},model:{value:(_vm.currentParameter.max),callback:function ($$v) {_vm.$set(_vm.currentParameter, "max", $$v)},expression:"currentParameter.max"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Unit"}},[_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"unitid","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"Unit ","options":_vm.units,"filter":_vm.filter,"reduce":function (option) { return option.id; },"disabled":_vm.action == 2},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.name))])]}},{key:"option",fn:function(ref){
var id = ref.id;
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(id)+" ")])]}}],null,true),model:{value:(_vm.currentParameter.unit),callback:function ($$v) {_vm.$set(_vm.currentParameter, "unit", $$v)},expression:"currentParameter.unit"}})],1),_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"disabled":_vm.actionType == 2,"id":"input-isParameterActive"},model:{value:(_vm.currentParamIsActive),callback:function ($$v) {_vm.currentParamIsActive=$$v},expression:"currentParamIsActive"}},[_vm._v(" Is Active ")]),_c('div',{staticClass:"d-flex mt-2"},[(_vm.actionType != 2)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.currentParameter == null || _vm.currentParameter.id == null ? "Add" : "Update")+" ")]):_vm._e(),(
              _vm.currentParameter != null &&
              _vm.currentParameter.id != null &&
              _vm.actionType == 2
            )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"type":"button","variant":"outline-danger"},on:{"click":_vm.deleteParameter}},[_vm._v(" Delete ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }